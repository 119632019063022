<template>
  <header
    class="fixed top-0 right-0 z-12 bg-white flex justify-end items-center px-4 lg:px-20 lg:pl-32 py-4 lg:py-6 h-20 w-full"
  >
    <div v-if="$store.state.general.loggedIn" class="flex">
      <button
        @click="logout"
        class="text-ansGreen text-base py-3 px-3 lg:px-5 font-source rounded"
      >
        Log out
      </button>

      <button @click="navOpen" class="block lg:hidden">
        <img src="@/assets/images/icons/menu.svg" alt="icons" />
      </button>

      <img
        :src="photograph"
        alt="user image"
        class="hidden lg:block w-10 h-10 object-fill rounded-full border border-ansBlack"
      />
    </div>
  </header>
</template>

<script>
export default {
  name: "InHeader",

  computed: {
    photograph() {
      return this.$store.state.general.user.photograph
        ? this.$store.state.general.user.photograph
        : require("@/assets/images/null_female.png");
    },
  },

  methods: {
    navOpen() {
      this.$store.commit("general/toggleNavBar");
    },
    logout() {
      this.$router.push({ name: "Home" });
      this.$store.commit("general/logout");
    },
  },
};
</script>

<style scoped>
header {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  /* border-bottom: 2px groove rgb(185, 178, 178); */
  /* z-index: 1; */
}
</style>
