<template>
  <transition name="slidex" appear="">
    <nav
      class="w-50 lg:w-60 bg-[#F9FBFF] absolute lg:static left-0 bottom-0 top-px font-source lg:pt-6 lg:px-5"
    >
      <logo color="black" />

      <ul class="mt-32">
        <li>
          <router-link
            :to="{ name: 'TaxPayer Dashboard' }"
            active-class="nav-active"
            @click.native="closeNav"
          >
            <img
              src="@/assets/images/icons/dashboard.svg"
              alt="icons"
              class="h-6 w-6"
            />
            <span>Overview</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'TaxPayer Invoices' }"
            active-class="nav-active"
            @click.native="closeNav"
          >
            <img
              src="@/assets/images/icons/invoices.svg"
              alt="icons"
              class="h-6 w-6"
            />
            <span>Invoices</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'TaxPayer Transactions' }"
            active-class="nav-active"
            @click.native="closeNav"
          >
            <img
              src="@/assets/images/icons/revenues.svg"
              alt="icons"
              class="h-6 w-6"
            />
            <span>Transactions</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'TaxPayer Receipts' }"
            active-class="nav-active"
            @click.native="closeNav"
            class="ml-1.5 flex items-center"
          >
            <i class="fas fa-receipt text-xl mr-1"></i>
            <span>Receipts</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </transition>
</template>

<script>
import Logo from "@/components/app/Logo.vue";
export default {
  name: "SideNav",

  components: {
    Logo,
  },

  methods: {
    closeNav() {
      this.$store.commit("general/toggleNavBar", false);
    },
  },
};
</script>

<style scoped>
nav {
  @apply border-r border-[#dddddd];
}
ul a {
  @apply hover:bg-ansGreen hover:text-white flex gap-3 px-6 py-3 text-[#252A2F] mt-1;
}
.nav-active {
  @apply bg-ansGreen text-white rounded font-semibold;
}
.nav-active > img,
ul a:hover > img {
  filter: invert(100%) sepia(46%) saturate(147%) hue-rotate(136deg)
    brightness(114%) contrast(100%) !important;
}

/* ****** Slide transistion */
.slidex-leave-active,
.slidex-enter-active {
  transition: 0.5s;
}
.slidex-enter {
  transform: translate(-100%, 0);
}
.slidex-leave-to {
  transform: translate(-100%, 0);
}
</style>
