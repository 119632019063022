<template>
  <div class="font-source">
    <section class="block lg:flex justify-between">
      <div class="text-base lg:text-xl">
        <h4 class="font-semibold text-ansBlack text-base lg:text-xl">
          Dashboard
        </h4>
        <small
          class="text-ghostWhite mt-0 lg:mt-1 text-base lg:text-xl capitalize"
        >
          Welcome
          <span class="text-base font-bold">{{
            $store.getters["general/userNames"]
          }}</span>
        </small>
      </div>

      <div
        class="flex justify-between gap-5 mt-5 lg:mt-0 w-full lg:w-auto font-semibold text-xs lg:text-base"
      >
        <button
          @click="showCreateInvoice"
          class="bg-ansGreen text-white px-2.5 lg:px-4 py-1.5 rounded flex gap-3 items-center w-1/2 lg:w-auto"
        >
          <span class="text-2xl">+</span>
          <span>Generate New Invoice</span>
        </button>
      </div>
    </section>

    <!-- Boxes -->
    <section class="mt-10">
      <!-- Small boxes -->
      <div class="flex gap-6 h-auto mt-8 overflow-x-scrol">
        <div class="small-boxes bg-ansGreen text-white">
          <h6 class="font-bold">
            Total Value of<br />
            <span class="ml-1 text-xs font-normal">TRANSACTIONS</span>
          </h6>

          <h3 class="mt-4 font-black">
            {{ totalTransactionAmount | toCurrency }}
          </h3>
        </div>

        <div class="small-boxes border border-cadeter">
          <h6 class="font-bold text-cadet">
            Value of Outstanding<br />
            <span class="ml-1 text-xs font-normal">INVOICES</span>
          </h6>

          <h3 class="mt-4 font-black text-ansBlack">
            {{ totalPendingInvoiceAmount | toCurrency }}
          </h3>
        </div>

        <div class="small-boxes border border-cadeter">
          <h6 class="font-bold text-cadet">
            Number for All<br />
            <span class="ml-1 text-xs font-normal">INVOICES</span>
          </h6>

          <h3 class="mt-4 font-black text-ansBlack">
            {{ totalNumberTransactions }}
          </h3>
        </div>

        <div class="small-boxes border border-cadeter">
          <h6 class="font-bold text-cadet">
            Number of Paid<br />
            <span class="ml-1 text-xs font-normal">INVOICES</span>
          </h6>

          <h3 class="mt-4 font-black text-ansBlack">
            {{ totalPaidInvoice }}
          </h3>
        </div>
      </div>
    </section>

    <!-- Tables -->
    <section class="mt-10">
      <h3 class="text-cadet font-bold text-base lg:text-2xl">
        Recent Activity
      </h3>

      <div class="block lg:flex justify-between font-source mt-3 pb-10 gap-10">
        <div class="table-box">
          <div class="flex justify-between px-2 mb-4">
            <h4>Invoices</h4>

            <router-link :to="{ name: 'TaxPayer Invoices' }">
              View all
            </router-link>
          </div>

          <my-table
            :table-data="recentInvoices"
            :fields="fInv"
            :show-page="false"
            :show-search="false"
          >
          </my-table>
        </div>

        <div class="table-box">
          <div class="flex justify-between px-2 mb-4">
            <h4>Transactions</h4>

            <router-link :to="{ name: 'TaxPayer Transactions' }">
              View all
            </router-link>
          </div>

          <my-table
            :table-data="recentTransactions"
            :fields="fTrans"
            :show-page="false"
            :show-search="false"
          >
          </my-table>
        </div>
      </div>
    </section>

    <Modal v-if="showCreateItem" v-model="showCreateItem" size="md">
      <AddRevenue @done="onAddItem" :userData="$store.state.general.user" />
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/app/Modal.vue";
import AddRevenue from "@/components/home/AddRevenue.vue";
export default {
  name: "TaxPayerDashboard",

  components: {
    Modal,
    AddRevenue,
  },

  async created() {
    await this.getData();
  },

  data() {
    return {
      getting: false,
      showCreate: false,
      showCreateItem: false,
      fInv: [
        {
          name: "invoiceNumber",
          title: "Invoice Number",
          titleClass:
            "w-1/3 text-left pl-4 bg-gray-100 py-2.5 text-cadet font-semibold text-sm",
          dataClass: "w-1/3 py-2 text-left pl-4 text-xs text-romanSilver",
        },
        {
          name: "invoiceStatus",
          title: "Status",
          titleClass:
            "w-1/3 bg-gray-100 py-2.5 text-cadet font-semibold text-sm",
          dataClass:
            "w-1/3 py-2 text-center text-xs text-romanSilver uppercase",
        },
        {
          name: "dateOfIssuance",
          title: "Created",
          titleClass:
            "w-1/3 bg-gray-100 py-2.5 text-cadet font-semibold text-sm",
          dataClass: "w-1/3 py-2 text-center text-xs text-romanSilver",
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
      ],
      fTrans: [
        {
          name: "referenceCode",
          title: "Tracking code",
          titleClass:
            "w-1/3 text-left pl-4 bg-gray-100 py-2.5 text-cadet font-semibold text-sm",
          dataClass: "w-1/4 py-2 text-left pl-4 text-xs text-romanSilver",
        },
        {
          name: "amount",
          title: "Amount (₦)",
          titleClass:
            "w-1/3 bg-gray-100 py-2.5 text-left text-cadet font-semibold text-sm",
          dataClass: "w-1/3 py-2 text-left text-xs text-romanSilver",
          formatter: (value) => this.moneyFormatter(value),
        },
        {
          name: "transactionStatus",
          title: "Status",
          titleClass:
            "w-1/3 bg-gray-100 py-2.5 text-left text-cadet font-semibold text-sm",
          dataClass: "w-1/4 py-2 text-left text-xs text-romanSilver uppercase",
        },
      ],
      recentInvoices: [],
      recentTransactions: [],
      totalTransactionAmount: 5209004.05,
      totalPaidInvoice: 7,
      totalPendingInvoiceAmount: 200535.05,
      totalNumberTransactions: 7,
    };
  },

  methods: {
    showCreateInvoice() {
      this.$store.commit("general/updateInvoiceData", {
        ...this.$store.state.general.user,
      });

      this.showCreateItem = true;
    },
    onAction(action, data, index) {
      console.log("slot action: " + action, data, index);
    },
    onAddAgent() {
      this.showCreate = false;
    },
    async onAddItem() {
      this.showCreateItem = false;
      this.$store.commit("general/wipeInvoice");
      this.$swal({
        icon: "success",
        text: "Invoice created successfully",
      });
      await this.getData();
    },
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get(
          `/dashboard?timestamp=${new Date().getTime()}`
        );
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.recentInvoices = data.data.recentInvoices;
        this.recentTransactions = data.data.recentTransactions;
        this.totalPaidInvoice = data.data.totalPaidInvoice;
        this.totalPendingInvoiceAmount = data.data.totalPendingInvoiceAmount;
        this.totalNumberTransactions = data.data.totalNumberTransactions;
        this.totalTransactionAmount = data.data.totalTransactionAmount;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
.small-boxes {
  @apply p-5 rounded w-1/4;
  min-width: 150px;
}
.table-box {
  @apply rounded-md py-4 px-2 w-full lg:w-1/2 mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
.table-box > div > a {
  @apply text-ansGreen font-semibold;
}
</style>
