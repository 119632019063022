<template>
  <div class="flex justify-between h-screen">
    <SideNav class="hidden lg:block nav-big w-50 z-[13]" />

    <section class="flex justify-between relative mt-18 lg:mt-24 z-1 w-full">
      <Header />

      <SideNav
        v-if="$store.state.general.sideOpen"
        class="nav-small block lg:hidden"
      />

      <main class="w-full px-4 pt-5 lg:px-9 lg:pt-10 bg-white">
        <router-view></router-view>
      </main>
    </section>
  </div>
</template>

<script>
import Header from "@/components/app/InHeader.vue";
import SideNav from "@/components/taxpayer/SideNav.vue";
export default {
  name: "TaxPayerAppFrame",

  components: {
    Header,
    SideNav,
  },

  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style scoped>
.nav-small {
  height: calc(100vh - 74px);
}
.nav-big {
  height: 100vh;
}
main {
  height: calc(100vh - 100px);
  overflow-y: scroll;
}
</style>
